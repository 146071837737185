import React, { useEffect, useState } from "react";
import "./HomePage.css"; // Make sure to create a corresponding CSS file
import crownIcon from "../icons/crown.png";
import personalBrandStrategy from "./icons/personal-brand.jpg";
import profileImage from "./icons/profile.png";
import socialImage from "./icons/social.jpg";
import contentPlanImage from "./icons/contentPlan.jpg";
import createContent from "./icons/create-content.svg";
import createContentText from "./icons/create-content-text.svg";
import createPost from "./icons/create-post.svg";
import firstPlan from "./icons/first-plan.svg";
import secondPlan from "./icons/second-plan.svg";
import facebookIcon from "./icons/facebook-icon.svg";
import instagramIcon from "./icons/instagram-icon.svg";
import manImage from "./icons/man.png";
import { Link } from "react-router-dom";
import { authActions, strategiesActions } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { jwtDecode } from "jwt-decode";
import { Tooltip } from "antd";
import { fetchWrapper } from "../../helpers";
import axios from "axios";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const HomePage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector((obj) => obj.auth.user);
  const [sid, setSid] = useState(
      jwtDecode(JSON.parse(localStorage.getItem("user")).accessToken)
          .subscriptionLevel.id
  );
  const [subDaysLeft, setSubDaysLeft] = useState(
    Math.round(
      moment
        .duration(
          moment(
            jwtDecode(user?.accessToken)?.userSubscription?.endDate ||
              moment().subtract(1, "M").startOf("day")
          ) - moment().startOf("day")
        )
        .asDays()
    )
  );

  const refreshToken = () => {
    fetchWrapper.get(`${baseUrl}/auth/refresh-token`).then((res) => {
      localStorage.setItem("user", JSON.stringify(res));
      setSubDaysLeft(
        Math.round(
          moment
            .duration(
              moment(
                jwtDecode(res?.accessToken)?.userSubscription?.endDate ||
                  moment().subtract(1, "M").startOf("day")
              ) - moment().startOf("day")
            )
            .asDays()
        )
      );
    });
  };

  useEffect(() => {
    refreshToken();
  }, []);

  // IMEAN
  // useEffect(() => {
  //   fetchWrapper.get('https://my.imean.io/api/strategy/actual/')
  //       .then((res)=>{
  //         console.log(res)
  //       }).catch((err)=>{
  //         console.log(err)
  //       })
  // }, []);

  return (
    <div className="home-page">
      <div className="menu-icon"></div>
      <div className="head-container">
        <div className="welcome-text">
          {t("home.welcomeBack")}
          {user?.name || ""}!
          {!!sid ? <img className='premium-icon' src={`${crownIcon}`} alt="premium"/> : null}
        </div>
        <button
          id="logout-desktop"
          className="generate-button"
          onClick={() => dispatch(authActions.logout())}
        >
          {t("home.logout")}
        </button>
      </div>
      <div className="card-container">
        <div className="card create-post">
          {subDaysLeft >= 0 && (
            <Tooltip
              title={t("home.subscriptionDaysLeft", {
                days: subDaysLeft === 0 ? 1 : subDaysLeft,
              })}
            >
              <div className="home-subscription-counter">
                {subDaysLeft === 0 ? 1 : subDaysLeft}
              </div>
            </Tooltip>
          )}
          <Link to="/profile">
            <div
              className="home-menu-title"
              style={{ backgroundImage: `url(${profileImage})` }}
            >
              {t("home.profile")}
            </div>
          </Link>
        </div>

        <Link to="/personal-brand" title={t("home.personalBrandStrategy")}>
          <div className="card personal-brand-strategy">
            <div
              className="home-menu-title"
              style={{ backgroundImage: `url(${personalBrandStrategy})` }}
            >
              {t("home.strategy")}
            </div>
          </div>
        </Link>
        <Link to="/content-plan" title={t("home.contentPlanTitle")}>
          <div className="card create-content-plan">
            <div
              className="home-menu-title"
              style={{ backgroundImage: `url(${contentPlanImage})` }}
            >
              {t("home.contentPlan")}
            </div>
          </div>
        </Link>
        {/* <div className="schedule-container">
          <div className="schedule-item-home facebook">
          <img src={facebookIcon} style={{zIndex:1, top: 0, left:20}} />
            <div className="schedule-time">10:00 AM</div>
            <div className="schedule-label">Post</div>
            <div className="schedule-description">Weekly forex market analysis with a focus on key currency trends.</div>
            <div className="schedule-action"></div>
              <img src={firstPlan} style={{zIndex:0}} />
          </div>
          
          <div className="schedule-item-home instagram">
              <img src={instagramIcon} style={{zIndex:1, top: 0, left:20}}/>
            <div className="schedule-time">1:00 PM</div>
            <div className="schedule-label">Post</div>
            <div className="schedule-description">In-depth analysis of a recent global economic event impacting forex markets.</div>
            <div className="schedule-action"></div>
              <img src={secondPlan}  style={{zIndex:0}}/>
          </div>
        </div> */}
        <Link to="/posts" title={t("home.createPostTitle")}>
          <div className="card create-post">
            <div
              className="home-menu-title"
              style={{ backgroundImage: `url(${socialImage})` }}
            >
              {t("home.createPost")}
            </div>
          </div>
        </Link>
      </div>
      <button
        id="logout-mobile"
        className="generate-button"
        onClick={() => dispatch(authActions.logout())}
      >
        {t("home.logout")}
      </button>
    </div>
  );
};

export default HomePage;
