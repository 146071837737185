import { Select } from "antd";
import { useState, useEffect } from "react";
import i18n from "i18next";

export const LanguageHeader = () => {
    const [language, setLanguage] = useState();

    const setLanguageLocalStorage = (value) => {
        localStorage.setItem("ln", value);
      };

    const onChangeLanguage = (ln) => {
        setLanguage(ln);
        setLanguageLocalStorage(ln);
    };

    const getLanguageLocalStorage = () => {
        return localStorage.getItem("ln");
      };

    useEffect(() => {
        i18n.changeLanguage(language);
    }, [language]);

    useEffect(() => {
        const localStorageLn = getLanguageLocalStorage();
        if (!localStorageLn) {
            setLanguageLocalStorage("en");
            setLanguage("en");
          } else {
            setLanguage(localStorageLn);
          }
    }, [])

    return(
        <div className="App">
            <div className="flex justify-end items-center rounded-b-lg w-full max-w-96 h-12 bg-violet-500 m-auto">
                <div>
                    <Select
                    onChange={onChangeLanguage}
                    value={language}
                    className="w-26 mr-2"
                    >
                    <Select.Option value="ru">Русский</Select.Option>
                    <Select.Option value="en">English</Select.Option>
                    </Select>
                </div>
            </div>
        </div>
    )
}