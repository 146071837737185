import PageHeader from "../../components/Header/PageHeader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import "./style.css";
import React, { useEffect, useState, useRef } from "react";
import { fetchWrapper } from "../../helpers";
import { Button, Modal } from "antd";
import visaMaster from "../../files/visa-master.png";
import payment_ru from "../../files/payment_ru.pdf";
import payment_en from "../../files/payment_en.pdf";
import { Tag } from "antd";
import crownIcon from "../icons/crown.png";
import {countdownTimer} from "../../utils/countdownTimer";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const SubscriptionPlansPage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [levels, setLevels] = useState([]);
  const user = useSelector((obj) => obj.auth.user);
  const [sid, setSid] = useState(
    jwtDecode(JSON.parse(localStorage.getItem("user")).accessToken)
      .subscriptionLevel.id
  );
  const [userSubscription, setUserSubscription] = useState(jwtDecode(JSON.parse(localStorage.getItem("user")).accessToken).userSubscription);
  const [subscriptionTimer, setSubscriptionTimer] = useState({
    message: '',
    intervalLink: null,
  });
  const interval = useRef(null);
  const payment = {
    ru: payment_ru,
    en: payment_en,
  };

  const refreshToken = () => {
    fetchWrapper.get(`${baseUrl}/auth/refresh-token`).then((res) => {
      localStorage.setItem("user", JSON.stringify(res));
      setSid(jwtDecode(res.accessToken).subscriptionLevel.id);
      setUserSubscription(jwtDecode(res.accessToken).userSubscription);
    });
  };

  const freedomPayInit = (sid) => {
    fetchWrapper.get(`${baseUrl}/freedom/request/${sid}`).then((res) => {
      window.location = res.redirectUrl;
    });
  };

  const cancelSubscription = () => {
    fetchWrapper.post(`${baseUrl}/cancel-subscription/`).then(() => {
      refreshToken();
      navigate("/profile");
    });
  };

  const handleButton = () => {
    setIsModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchWrapper.get(`${baseUrl}/subscribe/pricing/`).then((res) => {
      setLevels(res);
    });
    refreshToken();
  }, []);

  useEffect(() => {
    interval.current = setInterval(() => {
      refreshToken();
    }, 10000);

    return () => {
      clearInterval(interval.current);
    };
  }, [interval]);

  useEffect(() => {
    subscriptionTimer?.intervalLink && clearInterval(subscriptionTimer?.intervalLink);

    countdownTimer({
      endDate: new Date(userSubscription?.endDate),
      currentDate: new Date(),
      finalMessage: 'Время истекло!',
      setter: setSubscriptionTimer,
      locale: i18n.language,
    });

    return () => {
      subscriptionTimer?.intervalLink && clearInterval(subscriptionTimer?.intervalLink);
    };
  }, [userSubscription?.endDate]);
  return (
    <>
      <PageHeader
        title={t("subscriptionPlans.header")}
        onBackClick={() => {
          navigate("/home");
        }}
      />
      <div className="subscription-plans">
        {levels.map((level) => (
          <div className="subscription-plan__plan-card">
            <h2 className="plan-card__title">
              {t(`subscriptionPlans.${level.levelName}`)}{" "}
              {level.price > 0 && <img className='premium-icon me-1.5' src={`${crownIcon}`} alt="premium"/>}
              {sid === level.id && (
                  <Tag color="green">{t("subscriptionPlans.active")}</Tag>
              )}
              {(!sid && level.levelName === "free") && (
                  <Tag color="green">{t("subscriptionPlans.active")}</Tag>
              )}
            </h2>
            {/* <p className="plan-card__description">{t(`subscriptionPlans.${level.levelName}.description`)}</p> */}
            <p className="plan-card__price">{level.price}₸</p>
            {level.price > 0 ? (
              <>
                {sid === level.id ? (
                    <>
                      <small className='text-center my-2.5'>{t("profile.endSubscriptionDate")}<br/> {subscriptionTimer?.message}</small>

                      <button
                          onClick={() => freedomPayInit(level.id)}
                          className="plan-card__button --extend"
                      >
                        {t("profile.extendSubscription")}
                      </button>
                      <button
                          onClick={() => cancelSubscription(level.id)}
                          className="plan-card__button mt-2 --cancel"
                      >
                        {t("profile.cancelSubscription")}
                      </button>
                    </>
                ) : (
                    <button
                        onClick={() => freedomPayInit(level.id)}
                        className="plan-card__button"
                    >
                      {t("profile.buySubscription")}
                    </button>
                )}
              </>
            ) : (
              <></>
            )}
            {(level.levelName !== "paid" && level.levelName !== "assistant") ? (
              <ul className="plan-card__list">
                {level.features.actions.map((action) => (
                  <li>
                    {t(`subscriptionPlans.${action.type}`)}: {action.limit}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="plan-card__list">
                {t("subscriptionPlans.unlimitedRate")}
              </p>
            )}
          </div>
        ))}
      </div>
      <div
        className="main-content"
        style={{ marginTop: "40px", marginBottom: "20px", textAlign: "center" }}
      >
        <button
          className="survey-button"
          onClick={() => {
            showModal();
          }}
        >
          {t("subscriptionPlans.infoButton")}
        </button>
        <div className="signup-wrapper">
          <div className="signup-container">
            <div className="divider">
              <span className="divider-line"></span>
            </div>
            <div
              className="main-content"
              style={{
                marginTop: "40px",
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              <a className="signin-link" href={payment[i18n.language]}>
                <img src={visaMaster} style={{ cursor: "pointer" }} alt="" />
                <span className="link">{t("signin.payment")}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal
        title={t("subscriptionPlans.modalTitle")}
        open={isModalOpen}
        onOk={handleButton}
        onCancel={handleButton}
        footer={[
          <Button key="submit" onClick={handleButton} type="primary">
            {t("subscriptionPlans.submit")}
          </Button>,
        ]}
      >
        <p style={{ whiteSpace: "pre-line" }}>
          {t("subscriptionPlans.modalText")}
        </p>
      </Modal>
    </>
  );
};
