import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchWrapper } from "../helpers";
import { message } from "antd";

const name = "post";

const createInitialState = () => {
  return {
    post: null,
    loading: false,
    generating: false,
  };
};

const createExtraActions = () => {
  const baseUrl = `${process.env.REACT_APP_API_URL}`;

  const getPost = () => {
    return createAsyncThunk(
      `${name}/post`,
      async (contentPlanId) =>
        await fetchWrapper.get(`${baseUrl}/post/actual/${contentPlanId}`)
    );
  };

  const generatePost = () => {
    return createAsyncThunk(`${name}/postCreate`, async (contentPlanId) => {
      return await fetchWrapper.post(
        `${baseUrl}/post/generate/${contentPlanId}`
      );
    });
  };

  const generateImage = () => {
    return createAsyncThunk(
      `${name}/generateImage`,
      async ({ postId, contentPlanId }) => {
        await fetchWrapper.post(`${baseUrl}/post/generate-image/${postId}`);
        return await fetchWrapper.get(
          `${baseUrl}/post/actual/${contentPlanId}`
        );
      }
    );
  };

  const removeImage = () => {
    return createAsyncThunk(
        `${name}/removeImage`,
        async ({ postId, contentPlanId }) => {
          await fetchWrapper.delete(`${baseUrl}/post/remove-image/${postId}`);
          return await fetchWrapper.get(
              `${baseUrl}/post/actual/${contentPlanId}`
          );
        }
    );
  };

  const uploadImage = () => {
    return createAsyncThunk(
      `${name}/uploadImage`,
      async ({ postId, contentPlanId, image }) => {
        const formData = new FormData();
        formData.append("images", image);
        await fetchWrapper.post(
          `${baseUrl}/post/upload-image/${postId}`,
          formData
        );

        return await fetchWrapper.get(
          `${baseUrl}/post/actual/${contentPlanId}`
        );
      }
    );
  };



  return {
    getPost: getPost(),
    generatePost: generatePost(),
    generateImage: generateImage(),
    uploadImage: uploadImage(),
    removeImage: removeImage()
  };
};

const createExtraReducers = (builder) => {
  const getPost = () => {
    const { pending, fulfilled, rejected } = extraActions.getPost;
    builder.addCase(pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      if (action.payload?.status) {
        state.loading = false;
        state.generating = true;
      } else {
        state.post = action.payload;
        state.loading = false;
        state.generating = false;
      }
    });
    builder.addCase(rejected, (state, action) => {
      state.post = null;
      state.loading = false;
    });
  };

  const uploadImagePost = () => {
    const { pending, fulfilled, rejected } = extraActions.uploadImage;
    builder.addCase(pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      state.post = action.payload;
      state.loading = false;
    });
    builder.addCase(rejected, (state, action) => {
      state.post = null;
      state.loading = false;
    });
  };

  const generateImagePost = () => {
    const { pending, fulfilled, rejected } = extraActions.generateImage;
    builder.addCase(pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      state.post = action.payload;
      state.loading = false;
    });
    builder.addCase(rejected, (state, action) => {
      state.post = null;
      state.loading = false;
    });
  };

  const createPost = () => {
    const { pending, fulfilled, rejected } = extraActions.generatePost;
    builder.addCase(pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fulfilled, (state, action) => {
      state.loading = false;
      state.generating = true;
    });
    builder.addCase(rejected, (state, action) => {
      state.loading = false;
    });
  };

  getPost();
  createPost();
  uploadImagePost();
  generateImagePost();
};

const extraActions = createExtraActions();
const slice = createSlice({
  name: name,
  initialState: createInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    const extraReducers = createExtraReducers(builder);
  },
});

export const postActions = { ...slice.actions, ...extraActions };
export const postReducer = slice.reducer;
