import PageHeader from "../../components/Header/PageHeader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import "./style.css";
import React, { useEffect, useState } from "react";
import { fetchWrapper } from "../../helpers";
import { Select } from "antd";
import { useDebounce } from "use-debounce";
import i18n from "i18next";
import { getProvidersUrls } from "../../utils/auth";
import { PROVIDER } from "../../constants/auth";
import xIcon from "../icons/linkedin-icon.svg";
import { authActions } from "../../store";
import { useDispatch } from "react-redux";
import moment from "moment";
import crownIcon from "../icons/crown.png";

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const ProfilePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((obj) => obj.auth.user);
  const [language, setLanguage] = useState();
  const [name, setName] = useState(user.name);
  const [subDateEnd, setSubDateEnd] = useState();
  const [subLevel, setSubLevel] = useState();
  const [updateName] = useDebounce(name, 1000);
  const [tokens, setTokens] = useState({
    facebook: false,
    instagram: false,
    linkedIn: false,
  });
  const [sid, setSid] = useState(
      jwtDecode(JSON.parse(localStorage.getItem("user")).accessToken)
          .subscriptionLevel.id
  );
  const dispatch = useDispatch();
  const socialLogin = {
    linkedIn: () => {
      //   console.log(getProvidersUrls()[PROVIDER.LINKEDIN]);
      window.location = getProvidersUrls()[PROVIDER.LINKEDIN];
    },
  };


  useEffect(() => {
    fetchWrapper
      .get(`${baseUrl}/auth/getSocialMediaTokens/`)
      .then((res) => {
        const filteredToken = {
          facebook: false,
          instagram: false,
          linkedIn: false,
        };
        res.forEach((token) => {
          if (token.socialMediaType === "FACEBOOK") {
            filteredToken.facebook = true;
          }
          if (token.socialMediaType === "INSTAGRAM") {
            filteredToken.instagram = true;
          }
          if (token.socialMediaType === "LINKEDIN") {
            filteredToken.linkedIn = true;
          }
        });
        setTokens(filteredToken);
      })
      .catch(() => {});
  }, []);

  const refreshToken = () => {
    fetchWrapper.get(`${baseUrl}/auth/refresh-token`).then((res) => {
      const oldUser = JSON.parse(localStorage.getItem("user"));
      oldUser.languageGeneration = language;
      oldUser.name = name;
      oldUser.accessToken = res.accessToken;
      localStorage.setItem("user", JSON.stringify(oldUser));
      setSubDateEnd(jwtDecode(res?.accessToken)?.userSubscription?.endDate);
      setSubLevel(jwtDecode(res?.accessToken)?.subscriptionLevel);
      dispatch(authActions.updateUser());
    });
  };

  const onChangeLanguage = () => {
    if (language === "ru") {
      setLanguage("en");
    } else {
      setLanguage("ru");
    }
  };

  useEffect(() => {
    refreshToken();
    fetchWrapper.get(`${baseUrl}/profile/`).then((res) => {
      setLanguage(res.languageGeneration);
    });
  }, []);

  useEffect(() => {
    i18n.changeLanguage(language);
    fetchWrapper.post(`${baseUrl}/profile/update-info`, {
      languageGeneration: language,
    });
    refreshToken();
  }, [language]);

  useEffect(() => {
    fetchWrapper
      .post(`${baseUrl}/profile/update-info`, { name: updateName })
      .then(() => {
        refreshToken();
      });
  }, [updateName]);

  return (
    <>
      <PageHeader
        title={t("profile.header")}
        onBackClick={() => {
          navigate("/home");
        }}
      />
      <div className="profile">
        <div className="profile_info_container">
          <div
            style={{ display: "flex", alignItems: "center", columnGap: "5px" }}
          >
            <p className="profile_info__text">
              <span className="profile_info_title">{t("profile.name")}:</span>
            </p>
            <input
              type="name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            {!!sid ? <img className='premium-icon' src={`${crownIcon}`} alt="premium"/> : null}
          </div>
          <p className="profile_info__text">
            <span className="profile_info_title">{t("profile.login")}:</span>{" "}
            {user.email}
          </p>
          {subDateEnd && (
            <p className="profile_info__text">
              <span className="profile_info_title">
                {t("profile.subscription")}:
              </span>{" "}
              {moment(subDateEnd).format("DD/MM/YYYY")}
            </p>
          )}
          <div
            style={{ display: "flex", alignItems: "center", columnGap: "5px" }}
          >
            <p className="profile_info__text">
              <span className="profile_info_title">{t("profile.lang")}:</span>
            </p>
            <Select
              onChange={onChangeLanguage}
              value={language}
              className="w-26 mr-2"
            >
              <Select.Option value="ru">Русский</Select.Option>
              <Select.Option value="en">English</Select.Option>
            </Select>
          </div>
          <p className="profile_info__text">
            <span className="profile_info_title">
              {t("profile.linkAccount")}:
            </span>
          </p>
          <button
            className="social-button x"
            onClick={() => socialLogin.linkedIn()}
          >
            <span className="icon">
              <img width={30} src={xIcon} alt="" />
            </span>
            {tokens.linkedIn
              ? t("signin.linkedLinkedIn")
              : t("signin.linkLinkedIn")}
          </button>
          <p className="profile_info__text">
            <span className="profile_info_title">
              {subLevel?.id
                ? t("profile.upgradeSubscription")
                : t("profile.buySubscription")}
              :
            </span>
          </p>
          <button
            className="profile-button"
            onClick={() => navigate("/subscription-plans")}
          >
            {subLevel?.id
              ? t("profile.upgradeSubscription")
              : t("profile.buySubscription")}
          </button>
        </div>
      </div>
    </>
  );
};
