export function countdownTimer({ endDate, currentDate, finalMessage, setter, locale = 'ru' }) {
	const end = new Date(endDate);
	let now = new Date(currentDate);

	const intervalLink = setInterval(() => {
		now = new Date();
		const remainingTime = end - now;

		if (remainingTime <= 0) {
			clearInterval(intervalLink);
			setter({
				message: finalMessage,
				intervalLink,
			});
		}

		const seconds = Math.floor((remainingTime / 1000) % 60);
		const minutes = Math.floor((remainingTime / 1000 / 60) % 60);
		const hours = Math.floor((remainingTime / 1000 / 60 / 60) % 24);
		const days = Math.floor(remainingTime / 1000 / 60 / 60 / 24);

		let formattedTime;

		if (remainingTime > 72 * 60 * 60 * 1000) {
			const rtf = new Intl.RelativeTimeFormat(locale || 'ru', { numeric: 'auto' });
			formattedTime = rtf.format(days, 'day');
		} else if (remainingTime > 60 * 60 * 1000) {
			formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
		} else {
			formattedTime = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
		}

		setter({
			message: formattedTime,
			intervalLink,
		});
	}, 1000);

	setter({
		message: '',
		intervalLink,
	});
}
