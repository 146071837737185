export default {
    "signin": {
        "signIn": "Sign in",
        "welcomeBack": "Welcome Back !",
        "introText": "IMean Social: your smart assistant in social networks.",
        "emailLabel": "Email Address",
        "inputPassword": "Input password",
        "passwordLabel": "Password",
        "forgotPassword": "Forgot password ?",
        "dontHaveAccount": "Don`t have an account?",
        "or": "or",
        "continue": "Continue",
        "signInGoogle": "Sign in with Google",
        "signInApple": "Sign in with Apple",
        "signInFacebook": "Sign in with Facebook",
        "signInLinkedIn": "Sign in with LinkedIn",
        "linkFacebook": "Link Facebook account",
        "linkLinkedIn": "Link LinkedIn account",
        "linkInstagram": "Link Instagram account",
        "linkedFacebook": "Facebook already linked",
        "linkedLinkedIn": "LinkedIn already linked",
        "linkedInstagram": "Instagram already linked",
        "privacy": "The terms of the <offerLink>public offer</offerLink> and <privacyLink>privacy policy</privacyLink> have been fully read by me, I understand all the conditions, and I agree to all the terms",
        "payment": "Payment order",
        "contacts": 'Phone: +7 700 600 0001 \r\n Email: info@imean.kz'
    },
    "signup": {
        "signUp": "Sign Up",
        "fullName": "Full name",
        "passwordRequirements": "Ensure your password contains at least 8 characters - 1 uppercase, 1 lowercase, 1 number and 1 special symbol.",
        "haveAccount": "Already have an account?",
        "signUpGoogle": "Sign up with Google",
        "signUpApple": "Sign up with Apple",
        "signUpFacebook": "Sign up with Facebook",
        "signUpLinkedIn": "Sign up with LinkedIn",
        "lang": "Language"
    },
    "welcome": {
        "welcome": "Welcome {{username}} to IMean!",
        "howWeWork": "How we work",
        "completeSurvey": "In order to successfully create a personal brand, it is important to develop a strategy.",
        "completeSurvey2": "Based on this strategy, a content plan will be formed and publications will be prepared that take into account your goals and preferences.",
        "proceed": "Proceed to Survey",
        "warning": "It is very important to answer the questions as completely as possible so that the strategy is closer to your expectations.",
        "warning2": "In the process of our interaction with you, we will adjust it, from our point of view, for more accurate personalization."  
    },
    "profile":{
        "header": "Profile",
        "endSubscriptionDate": "Subscription expiration:",
        "buySubscription": "Buy subscription",
        "upgradeSubscription": "Update plan",
        "extendSubscription": "Extend subscription",
        "cancelSubscription": "Cancel subscription",
        "name": "Name",
        "login": "Login",
        "subscription": "Subscription until",
        "no": "No",
        "yes": "Yes",
        "lang": "Language",
        "linkAccount": "Link account"
    },
    "subscriptionPlans":{
        "header": "Rates",
        "Strategy": "Strategy",
        "ContentPlan": "Content plans",
        "LeadingQuestions": "Leading questions",
        "Post": "Posts",
        "Theme": "Themes",
        "PostImages": "Post images",
        "free": "Free",
        "paid": "Premium",
        "assistant": "Assistant",
        "infoButton": "Description of functionality",
        "modalTitle":"Functionality of the IMean service",
        "modalText": `
        1. Client Digital Strategy:\r\n
        Based on a small questionnaire, we create a client profile, which we call a digital strategy. In the future, posts will be created based on topics suggested by the client and considering their unique profile. The digital strategy is updated only after the user approves the updated version. We analyze the client’s requests and posts to understand which topics interest them and how they want to talk about them. This allows us to learn to create posts that more accurately reflect their goals and interests. Once the client approves the changes, our platform begins to create content that reflects the new preferences and helps better interact with the target audience. This ensures deeper personalization and improves social media interaction results.\r\n
        2. AI Post Creation:\r\n
        Knowing the client’s profile, our AI creates posts for LinkedIn that reflect the client’s preferences, experience, and aspirations. Our AI analyzes data and generates content that not only resonates with the client’s target audience but also helps achieve their professional goals. We strive to ensure that each post is highly personalized, contains relevant information, and enhances the client’s professional image.\r\n
        3. Weekly Content Plan:\r\n
        Our platform allows creating a weekly content plan, enabling clients to plan posts in advance and publish them on schedule. This ensures a consistent presence on LinkedIn without the need for daily intervention. Clients can easily view and edit scheduled posts, add their comments, and respond to feedback from the audience. This simplifies the content management process and allows maintaining active engagement with the professional network.\r\n
        4. Posting Outside the Content Plan:\r\n
        The platform also supports the ability to publish posts outside the content plan. If a client has an interesting idea for a post on a specific topic, the AI can create and publish this post immediately without needing to follow the pre-approved content plan. This gives clients the flexibility to quickly respond to current events, share relevant thoughts, and keep their LinkedIn profile lively and dynamic.
        `,
        "submit": "Ok",
        "unlimitedRate":"Unlimited for all functionality",
        "active": "Active"
    },
    "survey": {
        "survey": "Survey",
        "recording": "Recording voice.. Click to stop recording",
        "type": "or type your answer here...",
        "wait": "Please wait, we are saving your answers and generating information. It might take some time",
        "clear": "Clear",
        "submit":"Submit",
        "tapToStart": "Click to dictate your answer",
    },
    "home": {
        "welcomeBack": "Welcome back, ",
        "logout": "Logout",
        "contentPlan": "Create content plan",
        "strategy": "Digital strategy",
        "createPost": "Create a post",
        "linkSocials": "Link social accounts",
        "profile": "Profile",
        "personalBrandStrategy": "A personal brand is a public image of a person leading a professional activity that appears in the minds of the target audience",
        "contentPlanTitle": "Publication schedule",
        "createPostTitle": "Posting is a quick way to stay connected with your community. Any post you share is broadcast to your contacts' feeds on their LinkedIn home page",
        "subscriptionDaysLeft": "Number of days until subscription expires: {{days}}"
    },
    "personalBrand": {
        "coffee": `This might take 5-7 minutes.\r\nHow about a cup of invigorating coffee for some inspiration while the strategy is being formed?`,
        "header": "Digital strategy",
        "newStrategy": "Create new brand strategy",
        "Name": "Name",
        "Occupation/Title": "Title",
        "Short Bio": "Short Bio",
        "Accolades & Awards": "Accolades & Awards",
        "Strengths": "Strengths",
        "Weaknesses": "Weaknesses",
        "Passions": "Passions",
        "Values": "Values",
        "What Sets You Apart": "What Sets You Apart From The Peers",
        "Unique Benefits You Can Provide": "Unique Benefits You Can Provide",
        "Determine Who You are Trying to Reach and Communicate With": "Determine Who You are Trying to Reach and Communicate With",
        "Understand Their Needs, Wants, and Challenges": "Understand Their Needs, Wants, and Challenges",
        "How Should Daniel Address These Needs and Challenges": "How Should {{username}} Address These Needs and Challenges",
        "Colors": "Colors",
        "Imagery": "Imagery",
        "Personal Logo or Signature": "Personal Logo or Signature",
        "Areas of Expertise": "Areas of Expertise",
        "Content Formats": "Content Formats",
        "Content Themes": "Content Themes",
        "Engagement Strategy": "Engagement Strategy",
        "Short-term Goals (1-2 years)": "Short-term Goals (1-2 years)",
        "Long-term Goals (3-5 years)": "Long-term Goals (3-5 years)",
        "Feedback Channel": "Feedback Channel",
        "Adaptation Strategy": "Adaptation Strategy",
        "Conclusion": "Conclusion",
        "pdf": "Download PDF"
    },
    "sendPassword": {
        "header": "Forgot Password",
        "title": "Kindly input your email address to have a code sent",
        "placeholder": "Email address",
        "sendCode": "Send code"
    },
    "inputCode": {
        "header": "Input Code",
        "title": "Kindly input the code sent to your email address.",
        "placeholder": "Input code",
        "proceed": "Proceed"
    },
    "newPassword": {
        "noMatch": "Passwords do not match.",
        "header": "Create New Password",
        "passwordRequirements": "Ensure your password contains at least 8 characters - 1 uppercase, 1 lowercase, 1 number and 1 special symbol.",
        "placeholderCreate": "Create a new password",
        "placeholderConfirm": "Confirm password",
        "continue": "Continue"
    },
    "post": {
        "leadingQuestions": "Leading questions",
        "noLeading1": "In order to write personalized post press Generate to have insightful questions for the perfect post",
        "noLeading2": "Tap 'Generate' for insightful questions for perfect post",
        "loading": "Loading...",
        "generate": "Generate",
        "headerPost": "Post",
        "headerCreate": "Create Post",
        "suggestion": "Post suggestion",
        "published": "Published",
        "notpublished": "Not published",
        "planned": "Planned",
        "hashtagSuggestion": "Hashtag suggestions",
        "mediaSuggestion": "Media suggestions",
        "image": "Image",
        "noImage": "No image",
        "uploadImage": "Upload Image",
        "processingImage": "Plase wait, we are processing image",
        "save": "Save",
        "generateImage": "Generate Image",
        "generatingImage": "Generating Image",
        "generating": "We are generating your post"
    },
    "contentPlan": {
        "header": "Content Plan",
        "week": "Week",
        "add": "Click + to select social networks",
        "frequency": "Frequency",
        "elementsTitle": "Select or add elements for this weeks content",
        "custom": "Write your custom content requests here",
        "create": "Create content plan",
        "tokenError": "LinkedIn is not linked",
        "strategyError": "You have not generated a personal strategy"
    },
    "functions": {
        "generate": "Generate",
        "generating": "Generating...",
        "successfullyGenerated": "Successfully generated!",
        "successfullyFinished": "Successfully finished!",
        "successfullyUploaded": "Successfully uploaded!",
        "successfullySendToGenerate": "Successfully started the process of generating!",
        "wrong": "Something went wrong",
        "loading": "Loading...",
        "microphone": "Error accessing microphone, please check your device or give permission",
        "generatingPost": "Please wait, generating Post",
        "generatingContentPlan": "Please wait, generating Content Plan",
        "generatingBrand": "Please wait, generating Strategy",
        "loadingLeading": "Loading leading questions",
        "copied": "Copied !",
        "savingAnswersForStrategy": "Saving answers and generating strategy",
        "close": "Close",
        "loggedIn": "Successfully logged in!",
        "registered": "Successfully registered!",
        "incorrectCreds": "Incorrect credentials",
        "limitReached": "Action limit reached, used {{count}} of {{max}} by your rate",
        "recoveryRequested": "Recovery requested",
        "newPassword": "Password was reset",
        "error": "Error",
        "wrongLayout": "Wrong layout, need change language"
    },
    "socials": {
        "header": "Social networks",
        "linkAccounts": "Link social network accounts to enable auto-posting"
    },
    "plancard": {
        "generated": "Post generated",
        "notGenerated": "Post not generated",
        "approved": "Approved",
        "notApproved": "Not approved",
        "approve": "Approve post",
        "disapprove": "Disapprove post",
      },
      "posts":{
        "header": "Posts",
        "generatePost": "Generate post",
        "postDescription": "Enter a topic for post generation or dictate it using the microphone",
        "postDetails": "Details",
        "postDate": "Publication date",
        "publish": "Publish",
        "regenerate": "Regenerate post",
        "limit": "The limit of the rate has been reached. You can subscribe to a paid rate to continue using the service.",
        "goToRate": "Go to rates"
      },
      "hww":{
        "regTitle": "Registration in the service",
        "regSubtitle": "Register in the service",
        "strategyTitle": "Strategy Creation",
        "strategySubtitle": "Take the questionnaire and the AI will provide a personal strategy",
        "planTitle": "Creating content plan",
        "planSubtitle": "Based on the strategy, the AI will offer you a content plan",
        "postTitle": "Publishing posts",
        "postSubtitle": "Based on your strategy and content plan, the AI will suggest post ideas for you"
      }
}
