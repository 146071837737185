import React, { useState, useEffect, useRef } from "react";
import "../Survey/SurveyPage.css";
import activeMic from "../icons/mic-on.svg";
import facebookIcon from "../icons/facebook-content.svg";
import instagramIcon from "../icons/instagram-content.svg";
import linkedInIcon from "../icons/linkedin-content.svg";
import copyIcon from "../icons/copy-icon.svg";
import penIcon from "../icons/pen-icon.svg";
import checkIcon from "../icons/check-icon-black.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { contentPlanActions, postActions, questionsActions } from "../../store";
import { message } from "antd";
import PageHeader from "../../components/Header/PageHeader";
import moment from "moment";
import { fetchWrapper } from "../../helpers";
import { useTranslation } from "react-i18next";

const icons = {
  Facebook: facebookIcon,
  Instagram: instagramIcon,
  LinkedIn: linkedInIcon,
};

const baseUrl = `${process.env.REACT_APP_API_URL}`;

export const PostPage = () => {
  const post = useSelector((obj) => obj.post.post);
  const { t } = useTranslation();
  const params = useParams();
  const [image, setImage] = useState(null);
  const [generating, setGenerating] = useState(null);
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  const [editMainText, setEditMainText] = useState(false);
  const [editHashtag, setEditHashtag] = useState(false);
  const [editOffer, setEditOffer] = useState(false);
  const [mainText, setMainText] = useState(post.post);
  const [hashtags, setHashtags] = useState(post.hashtags);
  const [offer, setOffer] = useState(post.media);
  const navigate = useNavigate();
  console.log('post', post)
  console.log('image', image)
  const contentPlan = useSelector((obj) => obj.contentPlan.contentPlan);

  const changeMainText = () => {
    if (editMainText) {
      fetchWrapper.put(`${baseUrl}/post/update/${post.id}`, {
        post: mainText,
        hashtags,
        media: offer,
      });
    }
    setEditMainText(!editMainText);
  };

  const changeHashtags = () => {
    if (editHashtag) {
      fetchWrapper.put(`${baseUrl}/post/update/${post.id}`, {
        post: mainText,
        hashtags,
        media: offer,
      });
    }
    setEditHashtag(!editHashtag);
  };

  const changeOffer = () => {
    if (editOffer) {
      fetchWrapper.put(`${baseUrl}/post/update/${post.id}`, {
        post: mainText,
        hashtags,
        media: offer,
      });
    }
    setEditOffer(!editOffer);
  };

  const publishNow = () => {
    const d = new Date();
    fetchWrapper.put(`${baseUrl}/post/update/${post.id}`, {
      postInfo: {
        ...post,
        date: new Date(d.getTime() - d.getTimezoneOffset() * 60000)
          .toISOString()
          .slice(0, -5),
      },
    });
  };

  const clipboardCopy = (text) => {
    navigator.clipboard.writeText(text);
    message.success(t("functions.copied"));
  };

  const handleClick = (event) => {
    if (image) {
      setImage(null);
    } else {
      hiddenFileInput.current.click();
    }
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(event.target.files[0]);
    }
  };

  const handleRemoveGeneratedImage = async () => {
    await dispatch(
        postActions.removeImage({
          postId: post.id,
          contentPlanId: contentPlan.id,
          images: null,
        })
    );
  }

  const onSaveFile = async () => {
    if (image) {
      try {
        await dispatch(
          postActions.uploadImage({
            postId: post.id,
            contentPlanId: contentPlan.id,
            image,
          })
        );
        message.success(t("functions.successfullyUploaded"));
      } catch (err) {
        message.error(t("functions.wrong"));
      }
    } else {
      message.error(t("functions.noImage"));
    }
  };

  const onOkayButton = async () => {
    if (image) {
      try {
        await dispatch(
          postActions.uploadImage({
            postId: post.id,
            contentPlanId: contentPlan.id,
            image,
          })
        );
        message.success(t("functions.successfullyUploaded"));
        if (contentPlan.forIndependentPosts) {
          navigate("/posts");
        } else {
          navigate("/content-plan");
        }
      } catch (err) {
        message.error(t("functions.wrong"));
      }
    } else {
      if (contentPlan.forIndependentPosts) {
        navigate("/posts");
      } else {
        navigate("/content-plan");
      }
    }
  };

  const onGenerateImage = () => {
    setGenerating(true);
    message.loading(t("post.generatingImage"));
    dispatch(
      postActions.generateImage({
        postId: post.id,
        contentPlanId: contentPlan.id,
      })
    )
      .then(() => {
        message.success(t("functions.successfullyGenerated"));
      })
      .catch((err) => {
        message.error(t("functions.wrong"));
      })
      .finally(() => {
        setGenerating(false);
        setImage(false);
      });
  };
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {/* <PageHeader title={"Post"} />
            <div>
                <p className="self-assessment-title" style={{ fontSize: '1rem' }}>{contentPlan.description}</p>
            </div> */}
      <div className="max-w-lg m-auto">
        <div
          className="text-justify justify-center"
          style={{ textAlign: "center", fontSize: 20 }}
        >
          {t("post.suggestion")}
        </div>
        <div className="flex justify-between px-8 mt-8 items-center">
          <img src={icons[contentPlan.social_network]} width={40} />
          <div style={{ color: "#667085" }}>
            {post.published
              ? t("post.published")
              : `${t("post.planned")} ${contentPlan.weekDay} at ${moment(
                  contentPlan.date
                ).format("hh:mm")}`}
          </div>
          <div style={{ display: "flex" }}>
            <img
              className="cursor-pointer"
              onClick={() => {
                clipboardCopy(post.post);
              }}
              src={copyIcon}
            />
            <img
              className="cursor-pointer"
              onClick={() => changeMainText()}
              src={editMainText ? checkIcon : penIcon}
            />
          </div>
        </div>
        {editMainText ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <textarea
              style={{ boxShadow: "0px 0px 0px 9999px rgba(0, 0, 0, 0.3)" }}
              value={mainText}
              onChange={(e) => setMainText(e.target.value)}
              className=" h-80 resize-none justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4"
            ></textarea>
          </div>
        ) : (
          <div className="justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4">
            {mainText}
          </div>
        )}

        <div className="flex justify-between px-8 mt-8 items-center">
          <img width={40} />
          <div>{t("post.hashtagSuggestion")}</div>
          <div style={{ display: "flex" }}>
            <img
              className="cursor-pointer"
              onClick={() => {
                clipboardCopy(post.hashtags);
              }}
              src={copyIcon}
            />
            <img
              className="cursor-pointer"
              onClick={() => changeHashtags()}
              src={editHashtag ? checkIcon : penIcon}
            />
          </div>
        </div>
        {editHashtag ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <textarea
              style={{ boxShadow: "0px 0px 0px 9999px rgba(0, 0, 0, 0.3)" }}
              value={hashtags}
              onChange={(e) => setHashtags(e.target.value)}
              className=" h-24 resize-none justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4"
            ></textarea>
          </div>
        ) : (
          <div className="justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4">
            {hashtags}
          </div>
        )}

        <div className="flex justify-between px-8 mt-8 items-center">
          <img width={40} />
          <div>{t("post.mediaSuggestion")}</div>
          <div style={{ display: "flex" }}>
            <img
              className="cursor-pointer"
              onClick={() => {
                clipboardCopy(post.media);
              }}
              src={copyIcon}
            />
            <img
              className="cursor-pointer"
              onClick={() => changeOffer()}
              src={editOffer ? checkIcon : penIcon}
            />
          </div>
        </div>
        {editOffer ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <textarea
              style={{ boxShadow: "0px 0px 0px 9999px rgba(0, 0, 0, 0.3)" }}
              value={offer}
              onChange={(e) => setOffer(e.target.value)}
              className=" h-36 resize-none justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4"
            ></textarea>
          </div>
        ) : (
          <div className="justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4">
            {offer}
          </div>
        )}

        <div className="flex justify-center mt-8 items-center">
          <div>{t("post.image")}</div>
        </div>
        <div className="justify-center px-5 py-3 mt-2 mb-2 w-11/12 m-auto text-xs leading-7 text-justify text-black rounded-3xl bg-purple-200 bg-opacity-50 px-4 min-h-24">
          {image ? (
            <img
              className="m-auto"
              alt="preview image"
              src={URL.createObjectURL(image)}
            />
          ) : (post?.images || [null])[0] ? (
            <img className="m-auto" alt="preview image" src={post?.images[0]} />
          ) : (
            <div className="text-center mt-4">{t("post.noImage")}</div>
          )}
        </div>

        {post.images &&
            <div className='flex justify-center'>
              <button
                  onClick={handleRemoveGeneratedImage}
                  className="clear-button"
                  style={{ fontSize: 13, padding: 4 }}
              >
                {t("survey.clear")}
              </button>
            </div>
        }

        <div className="flex justify-center w-full h-full align-center mt-6">
          {generating ? (
            <div>{t("post.processingImage")}</div>
          ) : (
            <>
              {" "}
              <div>
                <button
                  onClick={handleClick}
                  className="clear-button"
                  style={{ fontSize: 13, padding: 4 }}
                >
                  {image ? t("survey.clear") : t("post.uploadImage")}
                </button>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }} // Make the file input element invisible
                />
              </div>
              {image ? (
                "" /* <button
                  onClick={onSaveFile}
                  className="submit-button p-0"
                  style={{ fontSize: 13, padding: 4 }}
                >
                  {t("post.save")}
              </button> */
              ) : (
                <button
                  onClick={onGenerateImage}
                  className="submit-button p-0"
                  style={{ fontSize: 13, padding: 4 }}
                >
                  {t("post.generateImage")}
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div style={{ display: "flex", columnGap: 10 }}>
        {/* <button
          onClick={publishNow}
          className="submit-button p-0"
          style={{ fontSize: 13, padding: 10, marginTop: 20, marginBottom: 20 }}
        >
          {t("posts.publish")}
        </button> */}

        <button
          onClick={onOkayButton}
          className="submit-button p-0"
          style={{ fontSize: 13, padding: 10, marginTop: 20, marginBottom: 20 }}
        >
          {t("post.save")}
        </button>

        <button
          onClick={() => dispatch(postActions.generatePost(contentPlan.id))}
          className="submit-button p-0"
          style={{
            fontSize: 13,
            padding: 10,
            marginTop: 20,
            marginBottom: 20,
            background: "red",
          }}
        >
          {t("posts.regenerate")}
        </button>
      </div>
    </div>
  );
};
